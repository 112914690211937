<template>
  <div>
    <AddNewDealer
      v-model="isAddNewDealer"
      @onAdd="fetchDataTable"
    />
    <EditDealer
      v-model="isEditDealer"
      :data-edit="dataEdit"
      @onUpdate="fetchDataTable"
    />
    <v-card>
      <v-card-title class="px-2">
        {{ $t('Dealer') }} <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="d-none d-md-block"
          @click="isAddNewDealer = true"
        >
          <v-icon class="me-1">
            {{ icons.mdiPlus }}
          </v-icon>
          <span class="d-none d-sm-block">
            {{ $t('add_dealer') }}
          </span>
        </v-btn>
        <v-btn
          color="primary"
          class="d-block d-md-none"
          fab
          outlined
          icon
          @click="isAddNewDealer = true"
        >
          <v-icon>
            {{ icons.mdiPlus }}
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-row class="px-2 mb-2">
        <v-col
          cols="12"
          md="6"
          lg="4"
        >
          <v-text-field
            v-model="searchtext"
            :label="$t('search')"
            :placeholder="$t('wordingSearch')"
            dense
            outlined
            hide-details
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="4"
        >
          <v-select
            v-model="drugfrom_status_id"
            :placeholder="$t('status')"
            :label="$t('status')"
            :items="statusList"
            :item-text="$i18n.locale"
            item-value="value"
            outlined
            dense
            hide-details
          ></v-select>
        </v-col>
      </v-row>
      <v-data-table
        :headers="tableColumns"
        :items="dataTableList"
        :options.sync="options"
        :loading="loading"
        disable-sort
        hide-default-footer
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template #[`item.drugfrom_id`]="{ index }">
          {{ index + 1 }}
        </template>
        <template #[`item.drugfrom_status_id`]="{ item }">
          <StatusBlock :status="item.drugfrom_status_id" />
        </template>
        <template #[`item.actions`]="{ item }">
          <v-tooltip
            color="#212121"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                small
                @click="dataEdit = item,isEditDealer = true"
                v-on="on"
              >
                <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("edit") }}</span>
          </v-tooltip>
          <v-tooltip
            color="#212121"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                :disabled="item.drugfrom_active_id=='2'"
                color="primary"
                small
                @click="drugfrom_id = item.drugfrom_id,drugfrom_status_id_update = item.drugfrom_status_id,isUpdateStatus = true"
                v-on="on"
              >
                <v-icon>{{ item.drugfrom_status_id==1? icons.mdiDeleteOutline :icons.mdiCheck }}</v-icon>
              </v-btn>
            </template>
            <span>{{ item.drugfrom_status_id==1?$t("suspend") :$t('normal') }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      v-model="isUpdateStatus"
      persistent
      max-width="350"
    >
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-icon
            class="me-1"
            color="warning"
          >
            {{ icons.mdiAlertOutline }}
          </v-icon> {{ drugfrom_status_id_update==1?$t('comfirmDealerSuspend'):$t('cancelDealerSuspend') }}
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            :loading="processLoading"
            :disabled="processLoading"
            @click="updateStatusDealer"
          >
            {{ $t('confirm') }}
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            @click="isUpdateStatus = false"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import {
  mdiPlus, mdiPencilOutline, mdiDeleteOutline, mdiCheck, mdiAlertOutline,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import useDealer from './useDealer'
import StatusBlock from '@/components/basicComponents/StatusBlock.vue'
import AddNewDealer from './AddNewDealer.vue'
import EditDealer from './EditDealer.vue'

export default {
  components: {
    StatusBlock,
    AddNewDealer,
    EditDealer,
  },
  setup() {
    const isAddNewDealer = ref(false)
    const isEditDealer = ref(false)
    const dataEdit = ref({})
    const {
      tableColumns,
      dataTableList,
      drugfrom_status_id,
      searchtext,
      statusList,
      options,
      loading,
      fetchDataTable,
      isUpdateStatus,
      processLoading,
      drugfrom_id,
      drugfrom_status_id_update,
      updateStatusDealer,
    } = useDealer()

    return {
      isUpdateStatus,
      processLoading,
      drugfrom_id,
      drugfrom_status_id_update,
      updateStatusDealer,
      isEditDealer,
      dataEdit,
      tableColumns,
      dataTableList,
      drugfrom_status_id,
      searchtext,
      statusList,
      options,
      loading,
      isAddNewDealer,
      fetchDataTable,
      icons: {
        mdiPlus, mdiPencilOutline, mdiDeleteOutline, mdiCheck, mdiAlertOutline,
      },
    }
  },
}
</script>
