<template>
  <div>
    <v-dialog
      v-model='isAddNewDealer'
      persistent
      max-width='900'
    >
      <v-card>
        <v-card-title>
          {{ $t('add_dealer') }}
        </v-card-title>
        <v-form
          ref='formAddNewDealer'
          @submit.prevent='createDealer'
        >
          <v-card-text>
            <v-row>
              <v-col cols='12' md='6' lg='4'>
                <v-text-field
                  v-model='drugfrom_name'
                  :rules='[required]'
                  hide-details='auto'
                  :label="$t('dealer')"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols='12' md='6' lg='4'>
                <v-text-field
                  v-model='drugfrom_tax'
                  :rules='[required]'
                  hide-details='auto'
                  :label="$t('tax_number')"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols='12' md='6' lg='4'>
                <v-text-field
                  v-model='drugfrom_tel'
                  :rules='[required]'
                  hide-details='auto'
                  :label="$t('tel')"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols='12' md='6' lg='4'>
                <v-text-field
                  v-model='drugfrom_address'
                  :rules='[required]'
                  hide-details='auto'
                  :label="$t('address')"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols='12' md='6' lg='4'>
                <v-text-field
                  v-model='drugfrom_district'
                  :rules='[required]'
                  hide-details='auto'
                  :label="$t('sub_district')"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols='12' md='6' lg='4'>
                <v-text-field
                  v-model='drugfrom_amphoe'
                  :rules='[required]'
                  hide-details='auto'
                  :label="$t('district')"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols='12' md='6' lg='4'>
                <v-text-field
                  v-model='drugfrom_province'
                  :rules='[required]'
                  hide-details='auto'
                  :label="$t('province')"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols='12' md='6' lg='4'>
                <v-text-field
                  v-model='drugfrom_zipcode'
                  :rules='[required]'
                  hide-details='auto'
                  :label="$t('zipcode')"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols='12' md='6' lg='4'>
                <v-text-field
                  v-model='drugfrom_website'
                  hide-details='auto'
                  :label="$t('website')"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols='12' md='6' lg='4'>
                <v-text-field
                  v-model='drugfrom_email'
                  :rules='[required]'
                  hide-details='auto'
                  :label="$t('email')"
                  dense
                  outlined
                ></v-text-field>
              </v-col>

            </v-row>


          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color='primary'
              type='submit'
              :loading='loading'
              :disabled='loading'
            >
              {{ $t('save') }}
            </v-btn>
            <v-btn
              color='secondary'
              outlined
              @click="$emit('update:is-add-new-dealer', false)"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { ref, watch } from '@vue/composition-api'
import { required } from '@/@core/utils/validation'
import dealer from '@/api/DrugManagement/dealer'
import store from '@/store'

export default {
  model: {
    prop: 'isAddNewDealer',
    event: 'update:is-add-new-dealer',
  },
  props: {
    isAddNewDealer: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const formAddNewDealer = ref(null)
    const shopName = ref(localStorage.getItem('shop_name'))
    const drugfrom_name = ref('')
    const drugfrom_tax = ref('')
    const drugfrom_tel = ref('')
    const drugfrom_address = ref('')
    const drugfrom_district = ref('')
    const drugfrom_amphoe = ref('')
    const drugfrom_province = ref('')
    const drugfrom_zipcode = ref('')
    const drugfrom_website = ref('')
    const drugfrom_email = ref('')

    const loading = ref(false)

    const { dealerAdd } = dealer

    const createDealer = () => {
      const isFormValid = formAddNewDealer.value.validate()
      if (!isFormValid) return
      loading.value = true
      dealerAdd({
        drugfrom_name: drugfrom_name.value,
        drugfrom_tax: drugfrom_tax.value,
        drugfrom_tel: drugfrom_tel.value,
        drugfrom_address: drugfrom_address.value,
        drugfrom_district: drugfrom_district.value,
        drugfrom_amphoe: drugfrom_amphoe.value,
        drugfrom_province: drugfrom_province.value,
        drugfrom_zipcode: drugfrom_zipcode.value,
        drugfrom_website: drugfrom_website.value,
        drugfrom_email: drugfrom_email.value,
      })
        .then(res => {
          store.commit('app/ALERT', {
            message: res.message, color: res.response ? 'success' : 'error',
          })
          loading.value = false
          emit('update:is-add-new-dealer', false)
          emit('onAdd')
          drugfrom_name.value = ''
        })
    }
    watch(() => props.isAddNewDealer, val => {
      if (val) {
        drugfrom_name.value = ''
      }
    })

    return {
      shopName,
      drugfrom_name,
      drugfrom_tax,
      drugfrom_tel,
      drugfrom_address,
      drugfrom_district,
      drugfrom_amphoe,
      drugfrom_province,
      drugfrom_zipcode,
      drugfrom_website,
      drugfrom_email,
      formAddNewDealer, required, createDealer, loading,
    }
  },

}
</script>
<style lang=''>

</style>
