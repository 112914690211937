<template>
  <div>
    <v-dialog
      v-model='isEditDealer'
      persistent
      max-width='900'
    >
      <v-card>
        <v-card-title>
          {{ $t('edit_dealer') }}
        </v-card-title>
        <v-form
          ref='formEditDealer'
          @submit.prevent='updateDealer'
        >
          <v-card-text>
            <v-row>
              <v-col cols='12' md='6' lg='4'>
                <v-text-field
                  v-model='dataEditLocal.drugfrom_name'
                  :rules='[required]'
                  hide-details='auto'
                  :label="$t('dealer')"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols='12' md='6' lg='4'>
                <v-text-field
                  v-model='dataEditLocal.drugfrom_tax'
                  :rules='[required]'
                  hide-details='auto'
                  :label="$t('tax_number')"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols='12' md='6' lg='4'>
                <v-text-field
                  v-model='dataEditLocal.drugfrom_tel'
                  :rules='[required]'
                  hide-details='auto'
                  :label="$t('tel')"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols='12' md='6' lg='4'>
                <v-text-field
                  v-model='dataEditLocal.drugfrom_address'
                  :rules='[required]'
                  hide-details='auto'
                  :label="$t('address')"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols='12' md='6' lg='4'>
                <v-text-field
                  v-model='dataEditLocal.drugfrom_district'
                  :rules='[required]'
                  hide-details='auto'
                  :label="$t('sub_district')"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols='12' md='6' lg='4'>
                <v-text-field
                  v-model='dataEditLocal.drugfrom_amphoe'
                  :rules='[required]'
                  hide-details='auto'
                  :label="$t('district')"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols='12' md='6' lg='4'>
                <v-text-field
                  v-model='dataEditLocal.drugfrom_province'
                  :rules='[required]'
                  hide-details='auto'
                  :label="$t('province')"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols='12' md='6' lg='4'>
                <v-text-field
                  v-model='dataEditLocal.drugfrom_zipcode'
                  :rules='[required]'
                  hide-details='auto'
                  :label="$t('zipcode')"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols='12' md='6' lg='4'>
                <v-text-field
                  v-model='dataEditLocal.drugfrom_website'
                  hide-details='auto'
                  :label="$t('website')"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols='12' md='6' lg='4'>
                <v-text-field
                  v-model='dataEditLocal.drugfrom_email'
                  :rules='[required]'
                  hide-details='auto'
                  :label="$t('email')"
                  dense
                  outlined
                ></v-text-field>
              </v-col>

            </v-row>


          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color='primary'
              type='submit'
              :loading='loading'
              :disabled='loading'
            >
              {{ $t('save') }}
            </v-btn>
            <v-btn
              color='secondary'
              outlined
              @click="$emit('update:is-edit-dealer', false)"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { ref, watch } from '@vue/composition-api'
import { required } from '@/@core/utils/validation'
import dealer from '@/api/DrugManagement/dealer'
import store from '@/store'

export default {
  model: {
    prop: 'isEditDealer',
    event: 'update:is-edit-dealer',
  },
  props: {
    isEditDealer: {
      type: Boolean,
      default: false,
    },
    dataEdit: {
      type: Object,
      default: () => ({}),
    },
  },

  setup(props, { emit }) {
    const formEditDealer = ref(null)
    const shopName = ref(localStorage.getItem('shop_name'))
    const dataEditLocal = ref({})
    const loading = ref(false)

    const { dealerUpdate } = dealer

    const updateDealer = () => {
      const isFormValid = formEditDealer.value.validate()
      if (!isFormValid) return
      loading.value = true
      dealerUpdate(dataEditLocal.value)
        .then(res => {
          store.commit('app/ALERT', {
            message: res.message, color: res.response ? 'success' : 'error',
          })
          loading.value = false
          emit('update:is-edit-dealer', false)
          emit('onUpdate')
          formEditDealer.value.reset()
        })
    }

    watch(() => props.dataEdit, val => {
      dataEditLocal.value = JSON.parse(JSON.stringify(val))
    })

    return {
      shopName, dataEditLocal, formEditDealer, required, updateDealer, loading,
    }
  },

}
</script>
<style lang=''>

</style>
