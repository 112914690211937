import { onMounted, ref, watch } from '@vue/composition-api'
import basicStatus from '@/@fake-db/data/basicStatus.json'
import dealer from '@/api/DrugManagement/dealer'
import { i18n } from '@/plugins/i18n'
import store from '@/store'

export default function useDealer() {
  // datatable valiables
  const tableColumns = ref([
    {
      text: '#',
      align: 'start',
      sortable: false,
      value: 'drugfrom_id',
      width: 30,
    },
    {
      text: i18n.t('drug_supplier'),
      width: 300,
      value: 'drugfrom_name',
    },
    {
      text: i18n.t('tax_number'),
      width: 200,
      value: 'drugfrom_tax',
    },
    {
      text: i18n.t('tel'),
      width: 200,
      value: 'drugfrom_tel',
    },

    {
      text: i18n.t('status'),
      value: 'drugfrom_status_id',
      width: 100,
    },
    {
      text: i18n.t('option'),
      value: 'actions',
      width: 200,
      align: 'center',
    },
  ])
  const dataTableList = ref([])
  const options = ref({})
  const loading = ref(false)
  const searchtext = ref('')
  const drugfrom_status_id = ref(1)
  const statusList = ref(basicStatus.data)

  // update status valiables
  const isUpdateStatus = ref(false)
  const drugfrom_id = ref('')
  const drugfrom_status_id_update = ref('')
  const processLoading = ref(false)

  const { dealerList, dealerUpdateStatus } = dealer
  onMounted(() => {
    fetchDataTable()
  })

  const updateStatusDealer = () => {
    processLoading.value = true
    dealerUpdateStatus(
      {
        drugfrom_id: drugfrom_id.value,
        drugfrom_status_id: drugfrom_status_id_update.value == '1' ? '2' : '1',
      },
    ).then(res => {
      store.commit('app/ALERT', {
        message: res.message,
        color: res.response ? 'success' : 'error',
      })

      processLoading.value = false
      fetchDataTable()
      isUpdateStatus.value = false
    })
  }

  const fetchDataTable = () => {
    loading.value = true
    const body = {
      searchtext: searchtext.value,
      drugfrom_status_id: drugfrom_status_id.value,
    }
    dealerList(body).then(res => {
      dataTableList.value = res
      options.value.itemsPerPage = -1
      loading.value = false
    })
  }

  watch([searchtext, drugfrom_status_id], () => {
    fetchDataTable()
  })

  return {
    isUpdateStatus,
    processLoading,
    drugfrom_id,
    drugfrom_status_id_update,
    updateStatusDealer,
    tableColumns,
    dataTableList,
    drugfrom_status_id,
    searchtext,
    statusList,
    options,
    loading,
    fetchDataTable,
  }
}
